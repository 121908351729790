import { Button, Dialog, Icon, Icons, IconSize } from '@in/component-library';
import React, { FC, useEffect, useRef } from 'react';
import { useTranslations } from '../../hooks/use-translations';

interface ConfirmationModalProps {
  visible?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose?: () => void;
  errorMessage?: boolean;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ visible = false, onClick, onClose, errorMessage }) => {
  const { translations } = useTranslations();
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (visible) {
      ref?.current?.showModal();
    } else {
      ref?.current?.close();
    }
  }, [visible]);

  return (
    <>
      <Dialog
        ref={ref}
        title={translations.applicationsModalConfirmDeletionTitle}
        className="application-card__cancel-dialog"
        closeButton={
          onClose
            ? {
                'aria-label': translations.close,
                onClick: () => {
                  onClose();
                },
              }
            : undefined
        }
        onClose={onClose}
      >
        <p>{translations.applicationsModalConfirmDeletionText}</p>

        {errorMessage && (
          <p>
            <Icon name={Icons.Error} inline={true} size={IconSize.Tiny} /> {translations.deletionModalFailed}
          </p>
        )}

        <div>
          <Button size="medium" variant="solid" onClick={onClick}>
            {translations.applicationsActionDelete}
          </Button>
          <Button size="medium" variant="outlined" onClick={onClose}>
            {translations.cancel}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
