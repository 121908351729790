import { FaIcon, FaIcons } from '@in/component-library';
import React, { FC } from 'react';
import { useTranslations } from '../../hooks/use-translations';
import { CaseHandledBy } from '../../models/statuspage/case-handled-by';
import { ApplicationStatus } from '../../models/statuspage/application-status';

interface ApplicationCaseInformationProps {
  modifiedByPerson?: string | undefined;
  caseHandledBy?: CaseHandledBy;
  projectUrl: string | undefined;
  formStatus: ApplicationStatus | undefined;
}

const ApplicationCaseinformation: FC<ApplicationCaseInformationProps> = ({ modifiedByPerson, caseHandledBy, projectUrl, formStatus }) => {
  const { translations } = useTranslations();
  return (
    <>
      <div className="application-information">
        <h3 className="application-information__header">{translations.formStatusHeader}</h3>

        <>
          <h4>{formStatus === ApplicationStatus.Draft ? translations.formModifiedBy : translations.formSubmittedBy} </h4>
          <p className="application-information__submitted-by data-hj-suppress">{modifiedByPerson?.toLowerCase()}</p>
        </>
      </div>
      {caseHandledBy && (
        <div className="application-information__case-handler">
          <h4>{translations.formAdvisor}</h4>
          {caseHandledBy.email ? (
            <a href={`mailto:${caseHandledBy.email}`}>
              <FaIcon name={FaIcons.EnvelopeLight} /> {caseHandledBy.name}
            </a>
          ) : (
            <p>{caseHandledBy.name}</p>
          )}
        </div>
      )}
      <div className="application-information__go-to-form">
        <h4 className="application-information__go-to-form__header">{translations.formStatusLinksAndFeatures}</h4>

        <div>
          <a className="application-information__go-to-form__link" href={projectUrl}>
            {translations.formStatusFormButtonText}
          </a>
          <FaIcon className="application-information__go-to-form__icon" name={FaIcons.ArrowRight} />
        </div>
      </div>
    </>
  );
};

export default ApplicationCaseinformation;
