import React, { FC, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router';
import jsSHA from 'jssha';
import { Layout } from './components/Layout';
import { FormsData } from './pages/Forms/FormsController';
import Home from './pages/Home/Home';
import { FormVMAPartners } from './components/Forms/FormVMAPartners';
import { AppContext } from './context/app-context';
import { UserStatus } from './models';
import { FormsInit } from './pages/Forms/FormsInit';
import StatusPage from './pages/status-page/status-page';
import { keepAlive } from './apis/keep-alive';

const generateNonce = () => {
  var array = new Uint8Array(10);
  window.crypto.getRandomValues(array);

  const shaObj = new jsSHA('SHA-256', 'UINT8ARRAY');
  shaObj.update(array);
  return shaObj.getHash('B64');
};

export const App: FC = () => {
  const { state } = useContext(AppContext);
  const nonce = generateNonce();

  // Keep authenticated user session alive
  useEffect(() => {
    const interval = setInterval(() => {
      if (state.user.status === UserStatus.LOGGED_IN) {
        keepAlive();
      }
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [state.user.status]);

  if (state.user.status === UserStatus.LOGGED_IN) {
    const sanitySource = 'https://cdn.sanity.io';
    const googleTagManagerSource = 'https://www.googletagmanager.com';
    const siteimproveSource = 'https://siteimproveanalytics.com/js/siteanalyze_6067402.js';
    const siteImproveDataDelivery = 'https://*.siteimproveanalytics.io';
    const thinkificSource = 'https://*.cdn.thinkific.com';
    const brregSource = 'https://data.brreg.no';
    const fontAwesomeSource = 'https://use.fontawesome.com/releases/v5.15.1/css/svg-with-js.css';
    const hotjarSources = 'http://*.hotjar.com https://*.hotjar.com http://*.hotjar.io https://*.hotjar.io wss://*.hotjar.com';
    const oneTrustSources = 'https://cdn.cookielaw.org/ https://geolocation.onetrust.com/';
    const cdnUriSource = 'https://component-library-dev-cdn.azureedge.net/';
    const signOutUrlSources = 'https://idporten.difi.no https://idporten-ver1.difi.no https://idporten-ver2.difi.no https://*.innovasjonnorge.no';
    const hashes = "'sha256-IgMQOOOedQeMPBl7lSreMVPmJvU62bc6l8HcsGXnbWc=' 'sha256-aJ/7uj7WvzTyAYMVNO4ZOv0ZoA6nIeLgg1voeVJtC8s=' ";

    return (
      <>
        <Helmet>
          <meta
            http-equiv="Content-Security-Policy"
            content={`
        default-src 'self';
        script-src 'self' 'nonce-${nonce}' 'unsafe-eval' ${googleTagManagerSource} ${hotjarSources} ${hashes} ${siteimproveSource} ${oneTrustSources} https://uxsignals-frontend.uxsignals.app.iterate.no;
        style-src 'self' 'unsafe-inline' ${fontAwesomeSource};
        img-src 'self' data: ${googleTagManagerSource} ${hotjarSources} ${sanitySource} ${thinkificSource} ${signOutUrlSources} ${oneTrustSources} ${siteImproveDataDelivery} https://uxsignals-frontend.uxsignals.app.iterate.no;
        connect-src 'self' wss: ${hotjarSources} ${oneTrustSources} ${brregSource} https://stats.g.doubleclick.net https://api.uxsignals.com;
        font-src 'self' ${hotjarSources} ${cdnUriSource};
        frame-src 'self' ${hotjarSources};
        `}
          />
          {/* <!-- Google Tag Manager --> */}
          <script nonce={nonce}>
            {`(function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-KQX6LZG');`}
          </script>
          {/* <!-- End Google Tag Manager --> */}
        </Helmet>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/VMApartners" element={<FormVMAPartners />} />
            <Route path="/form" element={<FormsInit />} />
            <Route path="/form/:formId" element={<FormsData />} />
            <Route path="/form/:formId/:page" element={<FormsData />} />
            <Route path="/form/:formId/status" element={<StatusPage />} />
            <Route path="/form/:formId/status/:index" element={<StatusPage />} />
          </Routes>
        </Layout>
      </>
    );
  }
  return <div />;
};
