import React, { FC } from 'react';
import CookieSettings from './cookie-settings/cookie-settings';
import { Footer as BaseFooter } from '@in/component-library';
import { useLocalizer, useResponsiveView } from '../hooks';
import './Footer.scss';

export const Footer: FC = () => {
  const { l } = useLocalizer('Footer');
  const { width } = useResponsiveView();
  return (
    <BaseFooter className="footer">
      <div>
        <h3>{l('firstColumnHeading')}</h3>
        <p>{l('firstColumnParagraph1')}</p>
        <p>{l('firstColumnParagraph2')}</p>
        <p>{l('firstColumnParagraph3')}</p>
        <p>{l('firstColumnParagraph4')}</p>
      </div>

      <div>
        <div>
          <h3>{l('secondColumnHeading')}</h3>
          <p>
            <a target="_blank" href={l('secondColumnParagraph1Link')} rel="noopener">
              {l('secondColumnParagraph1')}
            </a>
          </p>
          <p>
            <a target="_blank" href={l('secondColumnParagraph2Link')} rel="noopener">
              {l('secondColumnParagraph2')}
            </a>
          </p>
          <p>
            {l('secondColumnParagraph3part1')}
            <a target="_blank" href="https://www.innovasjonnorge.no/no/privacydeclaration/cookies/" rel="noopener">
              {l('secondColumnParagraph3part2')}{' '}
            </a>
          </p>
        </div>
        <CookieSettings />
      </div>
    </BaseFooter>
  );
};
