import React, { FC, useState, useEffect } from 'react';
import { useTranslations } from '../../hooks/use-translations';
import { CaseProcess } from '../../models/statuspage/case-process';
import { postSeenByRecipient } from '../../apis/seen-by-recipient';
import { Message } from '../../models/statuspage/message';
import MessageHeader from './message-header';
import { MessagesType } from '../../models/statuspage/messages-type';
import Feedback from '../feedback/feedback';

export interface MessagesProps {
  caseProcess: CaseProcess | undefined;
  formId: string | undefined;
}

const MessageResponse: FC<MessagesProps> = ({ caseProcess, formId }) => {
  const { translations } = useTranslations();

  const [showFeedback, setShowFeedback] = useState<boolean>(true);

  const handleSeenByRecipient = async (msg: Message) => {
    if (msg.seenByRecipient) return;
    return await postSeenByRecipient(formId, msg.id);
  };

  useEffect(() => {
    const firstUnseenMessage = caseProcess?.messages.find((msg) => msg.id === 0 && !msg.seenByRecipient);
    if (firstUnseenMessage) handleSeenByRecipient(firstUnseenMessage);
  }, [caseProcess?.messages]);

  useEffect(() => {
    if (caseProcess?.messages.some((msg) => msg.type === MessagesType.Feedback)) {
      setShowFeedback(false);
    }
  }, [caseProcess?.messages]);
  
  return (
    <>
      {caseProcess &&
        caseProcess.messages
          .filter((msg) => msg.id === 0)
          .map((msg) => (
            <div key={msg.id}>
              <div className="form-status__communication">
                <p className="form-status__communication--title">{translations.formStatusMessagesTitle}</p>
                <MessageHeader message={msg} display="--none" />
              </div>

              <div className="form-status__communication--body data-hj-suppress" dangerouslySetInnerHTML={{ __html: msg.content }} />
              {showFeedback && <Feedback message={msg} />}
            </div>
          ))}
    </>
  );
};

export default MessageResponse;