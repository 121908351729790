import { apiDispatcher } from '../utils';
import { Language } from '../models';
import { SessionConfiguration } from "../models/greenplatform.models";
import { DateTime } from 'luxon';

const baseUrl = "/api/v1/session";

export const getCurrentConfiguration = (): Promise<SessionConfiguration> => {
    return apiDispatcher<SessionConfiguration>(`${baseUrl}/current/configuration`, "GET");
}

export const setLanguage = (lang: Language) => {
    return apiDispatcher(`${baseUrl}/current/language`, "POST", lang, { resolver: (resp) => Promise.resolve(lang) });
}

export const logWebsocketDisconnect = (formId: string) => {
    return apiDispatcher(`${baseUrl}/loglostconnection`, "POST", { formId, disconnectedTime: DateTime.now().toISO() });
}